//Functions
function addActiveClassToElement(element, target) {
    const clickedElement = document.querySelector(element),
          targetToAddClass = document.querySelector(target);

    clickedElement.addEventListener('click', () => {
        clickedElement.classList.add('clicked');
        targetToAddClass.classList.add('active');

        if (!targetToAddClass.classList.contains('active')) {
            targetToAddClass.classList.remove('active');
        }

        if (targetToAddClass.classList.contains('sidebar') && targetToAddClass.classList.contains('active')) {
            document.body.classList.add('overflow-hidden');
        }

        if (targetToAddClass.classList.contains('sidebar-catalog') && targetToAddClass.classList.contains('active')) {
            document.querySelector('.sidebar-inner').classList.add('overflow-hidden');
        }
    });
}

function removeActiveClassFromElement(element, target) {
    const clickedElement = document.querySelector(element),
          targetToRemoveClass = document.querySelector(target);

    clickedElement.addEventListener('click', () => {
        if (targetToRemoveClass.classList.contains('active') || clickedElement.classList.contains('active')) {
            targetToRemoveClass.classList.remove('active');
            clickedElement.classList.remove('active');
        }

        if (targetToRemoveClass.classList.contains('sidebar-catalog')) {
            document.querySelector('.sidebar-inner').classList.remove('overflow-hidden');
        }
    });
}

function toggleActiveClass(trigger, scrollToTrigger = false) {
    const elements = document.querySelectorAll(trigger);

    elements.forEach(element => {
        element.addEventListener('click', function () {
            if (!this.classList.contains('active')) {
                elements.forEach(element => {
                    element.classList.remove('active');
                });
                this.classList.add('active');
            } else {
                this.classList.remove('active');
            }

            if (scrollToTrigger) {
                scrollToElement(trigger);
            }
        });
    });
}

function scrollToElement(hrefTo, triggerTo = false) {
    const element = document.querySelector(hrefTo),
        trigger = document.querySelector(triggerTo);

    if (triggerTo) {
        trigger.click();
    }
    element.scrollIntoView({
        behavior: "smooth",
        block: 'center'
    });
}

function mobileMenu() {
    const sidebar = document.querySelector('#sidebar'),
          overlay = document.getElementById('overlay'),
          menuToggle = document.querySelectorAll('.sidebar-menu-toggle'),
          sidebarHeader = document.querySelectorAll('.sidebar-header-menu'),
          activeMenu = document.querySelectorAll('.sidebar-menu-catalog'),
          sidebarClose = document.querySelectorAll('[data-sidebar="close"]');

    function closeSidebar() {
        sidebar.classList.remove('active');
        overlay.classList.remove('active');
        document.body.classList.remove('overflow-hidden');

        activeMenu.forEach(item => {
            item.classList.remove('active');
        });
    }

    menuToggle.forEach(item => {
        const menuToShow = item.querySelector('.sidebar-menu-catalog');

        item.addEventListener('click', (e) => {
            const target = e.target;

            if ((!target.classList.contains('sc-btn-close')) && (!target.classList.contains('sidebar-header-menu')) && (target.tagName.toLowerCase() !== 'a')) {
                menuToShow.classList.add('active');
            }
        });
    });

    sidebarHeader.forEach(item => {
        item.addEventListener('click', (e) => {
            let target = e.currentTarget;

            if (target !== item) {
                target = target.parentNode;
            }
            target.closest('.sidebar-menu-catalog').classList.remove('active');
        });
    });

    overlay.addEventListener('click', () => {
        closeSidebar();
    });

    sidebarClose.forEach(el => {
        el.addEventListener('click', () => {
            closeSidebar();
        });
    });
    
}

function megaMenu() {
    try {
        const menuToggleButton = document.querySelector('#menuToggleButton'),
            menuBox = document.querySelector('.sc-megamenu'),
            menuList = document.querySelector('.with-slideshow .sc-megamenu-list'),
            firstMenuItem = menuBox.querySelector('nav > ul > .sc-megamenu-list-item:first-child'),
            overlay = document.getElementById('overlay'),
            header = document.querySelector('header');

        let menuItems = document.querySelectorAll('.sc-megamenu-list-item');

        menuToggleButton.addEventListener('click', function (e) {
            const target = e.target;

            overlay.classList.toggle('active');
            target.classList.toggle('clicked');
            menuBox.classList.toggle('active');
            document.body.classList.toggle('overflow-hidden');
            header.style.zIndex = "10000";

            if (menuBox.classList.contains('active')) {
                firstMenuItem.classList.add('active');
            } else {
                firstMenuItem.classList.remove('active');
            }
        });

        menuItems.forEach(item => {
            item.addEventListener('mouseout', function () {
                item.classList.remove('active');
            });
        });

        overlay.addEventListener('click', () => {
            menuBox.classList.remove('active');
            menuToggleButton.classList.remove('clicked');
            document.body.classList.remove('overflow-hidden');
            firstMenuItem.classList.remove('active');
        });

        menuList.addEventListener('mouseenter', () => {
            header.style.zIndex = "10000";
            if (header.classList.contains('with-slideshow')) {
                overlay.classList.add('active');
                document.body.classList.add('overflow-hidden');
            }
        });

        menuList.addEventListener('mouseleave', () => {
            if (header.classList.contains('with-slideshow') && !menuBox.classList.contains('active')) {
                overlay.classList.remove('active');
                document.body.classList.remove('overflow-hidden');
                header.style.zIndex = "1";
            }
        });
            
        let observer = new IntersectionObserver(function (entries) {

            let elementsToRemoveClass = document.querySelectorAll('.with-slideshow');

            if (entries[0].isIntersecting === true) {
                menuBox.classList.add('with-slideshow');
                header.classList.add('with-slideshow');
                header.style.zIndex = "1";
            } else {
                elementsToRemoveClass.forEach((item) => {
                    item.classList.remove('with-slideshow');
                });
                header.style.zIndex = "10000";
            }

        }, {
            threshold: [0]
        });

        observer.observe(document.querySelector('.sc-slideshow-plus'));
    } catch(e) {}
}

function toggleColumnCategories() {
    const menuToggle = document.querySelectorAll('.sc-column-categories-item > span');

    menuToggle.forEach(element => {
        const menuToShow = element.closest('li').querySelector('.sc-column-categories-children');

        element.addEventListener('click', function (e) {
            const target = e.target;

            if ((target.tagName.toLowerCase() !== 'a') && menuToShow) {
                this.closest('li').classList.toggle('clicked');
                menuToShow.classList.toggle('active');
            }
        });
    });
}

function switchCategoryDisplay() {
    const listBtn = document.getElementById('list-view'),
          gridBtn = document.getElementById('grid-view'),
          priceBtn = document.getElementById('price-view');

    let productBlocks = document.querySelectorAll('.product-layout'),
        buttons = document.querySelectorAll('.sc-category-appearance-btn');

    function buttonsAction(button) {
        buttons.forEach(item => {
            item.classList.remove('active');
        });

        button.classList.add('active');

        productBlocks.forEach(item => {
            if (item.classList.contains('product-grid')) {
                item.classList.remove('product-grid', 'col-sm-6', 'col-md-4', 'col-lg-3');
            } else if (item.classList.contains('product-list')) {
                item.classList.remove('product-list', 'col-12', 'col-sm-6', 'col-md-12');
            } else {
                item.classList.remove('product-price', 'col-12', 'col-sm-6', 'col-md-12');
            }

            if (button.id == 'list-view') {
                item.classList.add('product-list', 'col-12', 'col-sm-6', 'col-md-12');
                localStorage.setItem('display', 'list');

                
            } else if (button.id == 'grid-view') {
                item.classList.add('product-grid', 'col-sm-6', 'col-md-4', 'col-lg-3');
                localStorage.setItem('display', 'grid');

            } else if (button.id == 'price-view') {
                item.classList.add('product-price', 'col-12', 'col-sm-6', 'col-md-12');
                localStorage.setItem('display', 'price');
            }
        });
    }

    if (localStorage.getItem('display') == 'grid') {
        buttonsAction(gridBtn);
    } else if (localStorage.getItem('display') == 'list') {
        buttonsAction(listBtn);
    } else if (localStorage.getItem('display') == 'price') {
        buttonsAction(priceBtn);
    }

    // Product List
    listBtn.addEventListener('click', function() {
        buttonsAction(this);
    });

    // Product Grid
    gridBtn.addEventListener('click', function () {
        buttonsAction(this);
    });

    // Product Price
    priceBtn.addEventListener('click', function () {
        buttonsAction(this);
    });
}

function reviewsRating(form) {
    const ratingForm = document.querySelector(form),
        ratingStars = ratingForm.querySelectorAll('.sc-module-rating-star');

    const previousSiblings = (elem) => {
        let siblings = [];

        while (elem = elem.previousElementSibling) {
            siblings.push(elem);
        }
        return siblings;
    };

    const nextSiblings = (elem) => {
        let siblings = [];

        while (elem = elem.nextElementSibling) {
            siblings.push(elem);
        }
        return siblings;
    };

    ratingStars.forEach((item) => {
        item.addEventListener('click', (e) => {
            const target = e.currentTarget,
                prevAll = previousSiblings(target),
                nextAll = nextSiblings(target);

            nextAll.forEach(el => el.classList.remove('sc-module-rating-star-is'));
            target.classList.add('sc-module-rating-star-is');
            prevAll.forEach(el => el.classList.add('sc-module-rating-star-is'));

        });
    });
}

function dropdownToggle() {
    let width = document.documentElement.clientWidth;

    if (width <= 1024) {
        $('body').on('click', '.sc-dropdown-box', function () {
            $(this).addClass('active');
            $('#rm_overlay').addClass('active');

        });

        $('body').on('click', '.sc-dropdown-toggle', function (event) {
            event.preventDefault();
        });
    } else {
        $('.sc-dropdown-box').hover(
        function () {
            $(this).addClass('active');
        },
        function () {
            $(this).removeClass('active');
        });
    }
}

function showProductButtons() {
    const element = document.querySelector('.sc-product-fixed-btns');

    let observer = new IntersectionObserver(function (entries) {

        if (entries[0].isIntersecting === true) {
            element.classList.remove('enabled');
        } else {
            element.classList.add('enabled');
        }

    }, {
        threshold: [0]
    });

    observer.observe(document.getElementById('product'));
    observer.observe(document.querySelector('.sc-product-images'));
}

// DOM loaded
window.addEventListener('DOMContentLoaded', () => {
    'use strict';

    toggleActiveClass('.sc-footer-middle-list .sc-footer-title', true);
    addActiveClassToElement('#mobile-menu-button', '#overlay');
    addActiveClassToElement('#mobile-menu-button', '#sidebar');
    addActiveClassToElement('#mobile-search-button', '#overlay');
    addActiveClassToElement('#mobile-search-button', '#sidebar');
    dropdownToggle();
});